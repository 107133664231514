import { Ref } from "vue";
import {
  FieldDataUpdateEmitPayload,
  FieldInterfaceEmitId,
} from "~/api/field-interfaces/emits";
import { IItem } from "~/entities/item";

export const useFieldsEventListeners = (item: Ref<IItem | undefined>) => {
  const fieldsEventListeners = {
    [FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA]: (data: FieldDataUpdateEmitPayload) => {
      if (!item.value) return;

      item.value.setDataProperty(data.fieldName, data.updatedData);
      if (!item.value.isDirty) item.value.setDirty();
    },
  };

  return { fieldsEventListeners };
};
